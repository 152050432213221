import React from 'react';
import styled, { css } from 'styled-components';

import {
	convertBytesToKb,
	getComponentSettings,
	getEmailLink,
	getLinkType,
	getLocalOrExternalUrl,
	getTelephoneLink,
	getUrl,
	isExternalUrl,
	shortenString,
} from 'libs/content';
import MaxWidth from 'layouts/max-width';
import GridBoxes from './GridBoxes';
import Shortcuts from './Shortcuts';
import ImageBoxes from './ImageBoxes';
import Spacing from 'layouts/Spacing';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import { SingleButton } from 'parts/title-and-text/Text';

//#region Styling

const MaxWidthWrapper = styled(MaxWidth)`
	${p =>
		p.$perrow === 1 &&
		css`
			max-width: ${p => p.theme.widths.medium};
		`}
`;

const ButtonsWrapper = styled.div`
	text-align: ${p => (p.$centered === 'true' && 'center') || 'left'};
	margin-top: ${p => p.theme.spacing.desktop.medium};
	${p =>
		p.theme.media.mediumDown(css`
			margin-top: ${p => p.theme.spacing.tablet.small};
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			margin-top: ${p => p.theme.spacing.mobile.xsmall};
		`)}
	button,
	a {
		margin: 0 20px 20px 0;
		&:last-of-type {
			margin-right: 0;
		}
	}
`;

//#endregion

/**
 * Represents elements a grid/list
 * @param {string} title - The title of the component
 * @param {object} introText - The introduction text of the component
 * @param {string} perrow - The number of elements per row (1-4)
 * @param {array} settings - The settings of the component
 * @param {array} boxes - The shortcuts for the component
 * @param {array} files - The files for the component
 * @param {array} buttons - The buttons at the bottom of the component
 * @param {string} headinglevel - The heading level of the title in the component
 * @param {...object} props - Rest of the parameters for the component
 */
export default function ContentGridElements({
	title = '',
	introText = {},
	perrow = 3,
	settings = [],
	elements = [],
	files = [],
	buttons = [],
	headinglevel = 'h2',
	...props
}) {
	const componentSettings = getComponentSettings({ settings, props });

	// Check if total count of elements and/or files is an even number or not
	const countIsEven = (elements?.length + files?.length) % 2 === 0;

	// Check if all elements is either "ContentfulKomponentBildeOgInnhold" or "ContentfulKomponentInnhold"
	const noneRelationalElements = elements?.some(el =>
		[
			'ContentfulKomponentBildeOgInnhold',
			'ContentfulKomponentInnhold',
		].includes(el?.internal?.type)
	);

	// Check if all elements has images
	const allElementsHasImages = elements?.some(
		el =>
			el?.meta?.image !== undefined ||
			el?.image?.file?.url !== undefined ||
			el?.image?.svg?.originalContent !== undefined
	);

	// Parse boxes
	const elementsArray = [];
	if (elements?.length > 0) {
		elements.forEach(element => {
			const {
				slug,
				to,
				title,
				desc,
				content,
				innhold,
				internal,
				icon,
				link,
			} = element;

			const data = {
				title,
				type: 'link',
				linktext: getLinkText(element),
				internal: !(
					element?.email || isExternalUrl(slug || to || link)
				),
				contenttype: internal?.type,
				target: isExternalUrl(slug || to) ? '_blank' : undefined,
				image: getImage(element),
				imageposition: element?.imageposition,
				link: getLink(element),
				icon,
			};

			// If has rawMarkdownBody, use that as text, otherwise use desc
			data.text =
				(desc?.childMarkdownRemark?.rawMarkdownBody &&
					shortenString(
						desc.childMarkdownRemark.rawMarkdownBody,
						200
					)) ||
				content ||
				innhold ||
				desc;

			elementsArray.push(data);
		});
	}

	// Parse files
	const filesArray = [];
	if (files?.length > 0) {
		files.forEach(file => {
			const type = file?.file?.url?.split('.').pop().split(/#|\?/)[0];
			filesArray.push({
				type: type?.toUpperCase(),
				link: file?.file?.url || file.url,
				internal: false,
				text: `Last ned`,
				target: '_blank',
				description: file?.description || file.tekst,
				kb:
					file?.file?.details?.size &&
					convertBytesToKb(file.file.details.size),
				...file,
			});
		});
	}

	const componentProps = {
		...props,
		elements: elementsArray,
		files: filesArray,
		perrow,
		headinglevel:
			(!noneRelationalElements ||
				componentSettings?.asshortcuts === 'true') &&
			props?.componentindex > 1
				? 'h3'
				: headinglevel,
		settings: componentSettings,
		evenCount: countIsEven,
		hasimages: allElementsHasImages ? 'true' : 'false',
	};

	let Component = null;
	if (allElementsHasImages && componentSettings?.imagegrid === 'true') {
		Component = ImageBoxes;
	} else if (
		componentSettings?.asshortcuts !== 'true' &&
		!files?.length > 0
	) {
		Component = GridBoxes;
	} else if (
		!noneRelationalElements ||
		componentSettings?.asshortcuts === 'true' ||
		(files?.length > 0 && componentSettings?.asshortcuts !== 'true')
	) {
		Component = Shortcuts;
	}

	return (
		<MaxWidthWrapper
			className="component__content-grid-elements max-width"
			data-cy="component__content-grid-elements"
			$perrow={perrow || 3}>
			<Spacing {...props}>
				{componentSettings?.hidetitle !== 'true' && (
					<TitleAndText
						title={title}
						text={introText}
						settings={settings}
						headinglevel={headinglevel}
						transitions={props?.transitions}
						nested={true}
						marginBottom="xsmall"
					/>
				)}

				<Component {...componentProps} />

				{buttons?.length > 0 && (
					<ButtonsWrapper $centered={componentSettings?.centered}>
						{buttons?.map((btn, i) => (
							<SingleButton
								key={i}
								button={btn}
								settings={componentSettings}
							/>
						))}
					</ButtonsWrapper>
				)}
			</Spacing>
		</MaxWidthWrapper>
	);
}

function getLink(element) {
	if (element?.anchorElement) {
		return element?.anchorElement;
	}

	if (!element?.link && !element?.slug && !element?.to && !element?.linkref) {
		return undefined;
	}

	const link = getUrl(
		element?.link || element?.slug || element?.linkref?.slug || element?.to,
		element?.internal?.type || element?.linkref?.internal?.type
	);

	switch (getLinkType(link)) {
		case 'email':
			return getEmailLink(link);
		case 'phone':
			return getTelephoneLink(link);
		default:
			return getLocalOrExternalUrl(link);
	}
}

function getImage(element) {
	const imageEl = element?.image || element?.meta?.image;

	if (!imageEl) return;

	return {
		url: imageEl?.file?.url,
		alt: imageEl?.description || element?.title,
		mimeType: imageEl?.mimeType,
		...imageEl,
	};
}

function getLinkText(element) {
	if (
		element?.internal?.type === 'ContentfulStrukturRedirect' &&
		element?.buttonText
	) {
		return element?.buttonText;
	}

	return `Gå til ${element?.title}`;
}
